<template>
  <div>
    <PageHeader>
      <template slot="title">戻る</template>
      <template slot="content">得意先一覧</template>
    </PageHeader>
    <template>案件一覧画面です。</template>
    <template
      >この画面で得意先情報の確認できますし、顧客名(黄色部分)を押すと顧客情報の編集できます。</template
    >
    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="20" :lg="16" :xl="12">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-input v-model="customer_name">
              <template slot="prepend">顧客名</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input v-model="telephone">
              <template slot="prepend">電話番号</template>
            </el-input>
          </el-col>
        </el-row>
        <br />
        <el-row :gutter="40">
          <el-col :span="12">
            <el-input v-model="personnel_name">
              <template slot="prepend">担当名</template>
            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <br />
    <el-row :gutter="10">
      <el-col :span="4">
        <el-button
          class="addBtn"
          type="info"
          @click="createBtn()"
          icon="el-icon-document-add"
          plain
          >新 規
        </el-button>
      </el-col>
    </el-row>
    <!--    <el-row :gutter="20">-->
    <!--      <el-col :span="24">-->
    <!--        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">-->
    <!--          <el-row type="flex" :gutter="40" justify="left">-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="customer_name">-->
    <!--                <el-input v-model="ruleForm.customer_name" placeholder="顧客名">-->
    <!--                  <template slot="prepend">顧客名</template>-->
    <!--                </el-input>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="personnel_name">-->
    <!--                <el-input v-model="ruleForm.personnel_name" placeholder="担当者名">-->
    <!--                  <template slot="prepend">担当者名</template>-->
    <!--                </el-input>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->
    <!--          <el-row type="flex" :gutter="40" justify="start">-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="telephone">-->
    <!--                <el-input v-model="ruleForm.telephone" placeholder="電話番号">-->
    <!--                  <template slot="prepend">電話番号</template>-->
    <!--                </el-input>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="is_delete">-->
    <!--                <el-switch-->
    <!--                  v-model="ruleForm.is_delete"-->
    <!--                  active-text="削除済み"-->
    <!--                  inactive-text="">-->
    <!--                </el-switch>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->
    <!--          <el-row type="flex" :gutter="20" justify="start">-->
    <!--            <el-col :span="12">-->
    <!--              <el-form-item>-->
    <!--                <el-button-group>-->
    <!--                  <el-button type="warning" @click="resetForm('ruleForm')">クリア</el-button>-->
    <!--                  <el-button type="primary" @click="submitForm('ruleForm')" icon="el-icon-search">検索</el-button>-->
    <!--                  <el-button type="success" @click="createBtn()" icon="el-icon-document-add">新規</el-button>-->
    <!--                </el-button-group>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->
    <!--        </el-form>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <el-row type="flex" :gutter="20" justify="left">
      <el-col :span="24">
        <el-divider></el-divider>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table
          :data="
            tableData
              .filter(
                (data) =>
                  !customer_name ||
                  data.customer_name
                    .toLowerCase()
                    .includes(customer_name.toLowerCase())
              )
              .filter(
                (data) =>
                  !telephone ||
                  data.telephone.toLowerCase().includes(telephone.toLowerCase())
              )
              .filter(
                (data) =>
                  !personnel_name ||
                  data.personnel_name
                    .toLowerCase()
                    .includes(personnel_name.toLowerCase())
              )
          "
          v-loading="loading"
          style="width: 100%"
        >
          <template slot="empty">得意先情報がありません</template>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="顧客CD：" sortable>
                  <span>{{ props.row.customer_code }}</span>
                </el-form-item>
                <el-form-item label="顧客名：">
                  <a
                    @click="handleEdit(props.$index, props.row)"
                    class="rowTxt"
                  >
                    <span>
                      {{ props.row.customer_name }}
                    </span>
                  </a>
                </el-form-item>
                <el-form-item label="担当者：">
                  <span>{{ props.row.personnel_name }}</span>
                </el-form-item>
                <el-form-item label="部署：">
                  <span>{{ props.row.department1 }}</span>
                </el-form-item>
                <el-form-item label="部署2：">
                  <span>{{ props.row.department2 }}</span>
                </el-form-item>
                <el-form-item label="部署3：">
                  <span>{{ props.row.department3 }}</span>
                </el-form-item>
                <el-form-item label="電話番号：">
                  <span>{{ props.row.telephone }}</span>
                </el-form-item>
                <el-form-item label="FAX：">
                  <span>{{ props.row.fix }}</span>
                </el-form-item>
                <el-form-item label="郵便番号：">
                  <span>{{ props.row.postal_number }}</span>
                </el-form-item>
                <el-form-item label="都道府県：">
                  <span>{{ props.row.address1 }}</span>
                </el-form-item>
                <el-form-item label="市区町村：">
                  <span>{{ props.row.address2 }}</span>
                </el-form-item>
                <el-form-item label="建物名：">
                  <span>{{ props.row.address3 }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="No." label="No." type="index">
          </el-table-column>
          <el-table-column prop="customer_code" label="顧客CD">
          </el-table-column>
          <el-table-column prop="customer_name" label="顧客名">
          </el-table-column>
          <el-table-column prop="personnel_name" label="担当者">
          </el-table-column>
          <el-table-column prop="department1" label="部署"> </el-table-column>
          <el-table-column prop="telephone" label="電話番号"> </el-table-column>
          <el-table-column prop="postal_number" label="郵便番号">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div>
      <el-dialog
        title="顧客情報登録・更新"
        :visible.sync="dialogFormVisible"
        v-if="dialogFormVisible"
        top="5%"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <CustomerForm
          :customerCode="customer_code"
          @cancel="closeDialog"
          @submit="reloadList"
        ></CustomerForm>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getCustomerDataAll, searchCustomerData } from "api/customers";
import PageHeader from "components/common/menu/PageHeader";
import CustomerForm from "views/master/CustomerForm";

export default {
  name: "CustomerList",
  components: {
    PageHeader,
    CustomerForm,
  },
  data() {
    return {
      ruleForm: {
        customer_name: "",
        personnel_name: "",
        telephone: "",
        is_delete: false,
      },
      customer_name: "",
      personnel_name: "",
      telephone: "",
      tableData: [],
      loading: false,
      dialogFormVisible: false,
    };
  },
  created() {
    this.getCustomerDataAll();
  },
  methods: {
    getCustomerDataAll() {
      this.loading = true;
      getCustomerDataAll()
        .then((res) => {
          if (res.data.status === 1) {
            this.tableData = res.data.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchCustomerData() {
      let values = {};
      values["customer_name"] = this.ruleForm.customer_name;
      values["personnel_name"] = this.ruleForm.personnel_name;
      values["telephone"] = this.ruleForm.telephone;
      values["is_delete"] = this.ruleForm.is_delete;
      searchCustomerData(values)
        .then((res) => {
          this.tableData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.searchCustomerData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    reloadList() {
      this.dialogFormVisible = false;
      this.getCustomerDataAll();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.getCustomerDataAll();
    },
    handleEdit(index, row) {
      this.customer_code = row.customer_code;
      this.dialogFormVisible = true;
    },
    createBtn() {
      this.customer_code = "";
      this.dialogFormVisible = true;
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
};
</script>

<style scoped>
.demo-table-expand {
  font-size: 0;
  margin-left: 50px;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
