<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="customer_name">
            <el-input v-model="ruleForm.customer_name" placeholder="得意先名">
              <template slot="prepend">得意先名</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="customer_code">
            <el-input
              v-model="ruleForm.customer_code"
              placeholder="得意先CD"
              :disabled="!!this.customerCode"
            >
              <template slot="prepend">得意先CD</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="department1">
            <el-input v-model="ruleForm.department1" placeholder="部署I">
              <template slot="prepend">部署I</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="department2">
            <el-input v-model="ruleForm.department2" placeholder="部署II">
              <template slot="prepend">部署II</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="department3">
            <el-input v-model="ruleForm.department3" placeholder="部署III">
              <template slot="prepend">部署III</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="personnel_name">
            <el-input v-model="ruleForm.personnel_name" placeholder="担当者">
              <template slot="prepend">担当者</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="telephone">
            <el-input v-model="ruleForm.telephone" placeholder="電話番号">
              <template slot="prepend">電話番号</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="fix">
            <el-input v-model="ruleForm.fix" placeholder="FAX">
              <template slot="prepend">FAX</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="postal_number">
            <el-input v-model="ruleForm.postal_number" placeholder="郵便番号">
              <template slot="prepend">郵便番号</template>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchAddress"
              ></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item prop="address1">
            <el-input v-model="ruleForm.address1" placeholder="都道府県">
              <template slot="prepend">都道府県</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="address2">
            <el-input v-model="ruleForm.address2" placeholder="市区町村">
              <template slot="prepend">市区町村</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="address3">
            <el-input v-model="ruleForm.address3" placeholder="建物">
              <template slot="prepend">建物</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item>
            <el-button-group>
              <el-button
                type="warning"
                @click="cancelForm()"
                icon="el-icon-document-delete"
                plain
                >閉じる</el-button
              >
              <el-button
                type="primary"
                @click="submitForm('ruleForm')"
                icon="el-icon-document-checked"
                plain
                >登録・更新</el-button
              >
            </el-button-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getAddress } from "api/postal";
import {
  postCustomerData,
  putCustomerData,
  getCustomerDataOne,
} from "api/customers";
// import PageHeader from 'components/common/menu/PageHeader'
import { Message } from "element-ui";

export default {
  name: "CustomerForm",
  components: {
    // PageHeader
  },
  props: {
    customerCode: {
      type: String, //指定传入的类型
      //type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: "", //这样可以指定默认的值
    },
  },
  data() {
    return {
      labelPosition: "left",
      ruleForm: {
        customer_code: "",
        customer_name: "",
        department1: "",
        department2: "",
        department3: "",
        personnel_name: "",
        postal_number: "",
        address1: "",
        address2: "",
        address3: "",
        telephone: "",
        fix: "",
      },
      rules: {
        customer_name: [
          {
            required: true,
            message: "得意先名を入力してください",
            trigger: "blur",
          },
        ],
        customer_code: [
          {
            required: true,
            message: "得意先CDを決めてください",
            trigger: "blur",
          },
        ],
        department1: [
          { required: true, message: "部署Iは必須項目です。", trigger: "blur" },
        ],
        personnel_name: [
          { required: true, message: "担当者は誰ですか？", trigger: "blur" },
        ],
        telephone: [
          {
            required: true,
            message: "電話番号を入力してください",
            trigger: "blur",
          },
        ],
        postal_number: [
          {
            required: true,
            message: "郵便番号を入力してください",
            trigger: "blur",
          },
          {
            min: 7,
            max: 8,
            message: "正しい郵便番号を入力してください",
            trigger: "blur",
          },
        ],
        address1: [
          {
            required: true,
            message: "住所を入力してください",
            trigger: "blur",
          },
        ],
        address2: [
          {
            required: true,
            message: "番地まで入力してください",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created: function () {
    if (this.customerCode) {
      this.getCustomerDataOne();
    }
  },
  methods: {
    postCustomerData() {
      let values = {};
      values["customer_code"] = this.ruleForm.customer_code;
      values["customer_name"] = this.ruleForm.customer_name;
      values["department1"] = this.ruleForm.department1;
      values["department2"] = this.ruleForm.department2;
      values["department3"] = this.ruleForm.department3;
      values["personnel_name"] = this.ruleForm.personnel_name;
      values["postal_number"] = this.ruleForm.postal_number;
      values["address1"] = this.ruleForm.address1;
      values["address2"] = this.ruleForm.address2;
      values["address3"] = this.ruleForm.address3;
      values["telephone"] = this.ruleForm.telephone;
      values["fix"] = this.ruleForm.fix;
      postCustomerData(values)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    putCustomerData() {
      let values = {};
      values["customer_code"] = this.ruleForm.customer_code;
      values["customer_name"] = this.ruleForm.customer_name;
      values["department1"] = this.ruleForm.department1;
      values["department2"] = this.ruleForm.department2;
      values["department3"] = this.ruleForm.department3;
      values["personnel_name"] = this.ruleForm.personnel_name;
      values["postal_number"] = this.ruleForm.postal_number;
      values["address1"] = this.ruleForm.address1;
      values["address2"] = this.ruleForm.address2;
      values["address3"] = this.ruleForm.address3;
      values["telephone"] = this.ruleForm.telephone;
      values["fix"] = this.ruleForm.fix;
      putCustomerData(values)
        .then((res) => {
          if (res.status == 200) {
            this.$emit("submit");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$emit("cancel");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCustomerDataOne() {
      let values = {};
      values["customer_code"] = this.customerCode;
      getCustomerDataOne(values)
        .then((res) => {
          this.ruleForm.customer_code = res.data.data[0].customer_code;
          this.ruleForm.customer_name = res.data.data[0].customer_name;
          this.ruleForm.department1 = res.data.data[0].department1;
          this.ruleForm.department2 = res.data.data[0].department2;
          this.ruleForm.department3 = res.data.data[0].department3;
          this.ruleForm.personnel_name = res.data.data[0].personnel_name;
          this.ruleForm.postal_number = res.data.data[0].postal_number;
          this.ruleForm.address1 = res.data.data[0].address1;
          this.ruleForm.address2 = res.data.data[0].address2;
          this.ruleForm.address3 = res.data.data[0].address3;
          this.ruleForm.telephone = res.data.data[0].telephone;
          this.ruleForm.fix = res.data.data[0].fix;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.customerCode) {
            this.putCustomerData();
          } else {
            this.postCustomerData();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 调用了别人的接口服务，不知道什么时候会停止
    getAddress() {
      getAddress({
        url: "/?zipcode=" + this.ruleForm.postal_number,
        method: "get",
      })
        .then((res) => {
          console.log(res.data);
          if (res.data.code === 200) {
            this.ruleForm.address1 = res.data.data.pref;
            this.ruleForm.address2 = res.data.data.address;
          } else {
            alert("住所を見つけませんでした!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields()
    // },
    cancelForm() {
      this.$emit("cancel");
    },
    searchAddress() {
      this.getAddress();
    },
  },
};
</script>

<style scoped>
/*.el-input {*/
/*  width: 300px*/
/*}*/
</style>
