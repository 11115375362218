import {request} from 'network/request'

export function postCustomerData(paramenter) {
  return request({
    url: '/customer',
    method: 'post',
    data: paramenter
  })
}

export function putCustomerData(paramenter) {
  return request({
    url: '/customer',
    method: 'put',
    data: paramenter
  })
}


export function getCustomerDataAll(paramenter) {
  return request({
    url: '/customer',
    method: 'get',
    data: paramenter
  })
}

export function getCustomerDataOne(paramenter) {
  return request({
    url: '/customer/' + paramenter.customer_code,
    method: 'get',
    data: paramenter
  })
}

export function searchCustomerData(parameter) {
  return request({
    url: '/SearchCustomerData',
    method: 'post',
    data: parameter
  })
}
