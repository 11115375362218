<template>
  <el-page-header @back="goBack" content="详情页面">
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <template slot="content">
      <slot name="content"></slot>
    </template>
  </el-page-header>
</template>

<script>
export default {
  name: "PageHeader",
  methods: {
    goBack() {
      if (window.history.length <= 1) {
        this.$router.push({path: '/ProjectList'})
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped>
.el-page-header {
  display: flex;
  line-height: 50px;
}
</style>
